import React from 'react'
import AboutUsTemplate from '../pagesTemplates/about-us'
import gatsbyi18Context from '../contexts/gatsby-i18n-context.js'

export default (props) => {
  const { pageContext } = props
  const { langKey: language } = pageContext

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <AboutUsTemplate {...props} />
    </gatsbyi18Context.Provider>
  )
  return 
}

export const query = graphql`
  query {
    page: wordpressPage (slug: {
      eq: "about-us-en"
    }) {
      title
      content
    }
  }
`

